export type Success<T> = {
  success: true
  failure: false
  value: T
}

export type Failure<E> = {
  success: false
  failure: true
  error: E
}

export type Result<T, E> = Success<T> | Failure<E>

const success = <T>(value: T): Success<T> => ({
  success: true,
  failure: false,
  value,
})

const failure = <E>(error: E): Failure<E> => ({
  success: false,
  failure: true,
  error,
})

export const useResult = () => {
  return { success, failure }
}
