import type { TypedDocumentNode } from '@urql/vue'
import type { GraphQLError } from 'graphql'

export const useGraphqlApi = () => {
  const nuxt = useNuxtApp()
  const { success, failure } = useResult()

  const parseErrors = (errors: GraphQLError[]): string[] => {
    return errors.map((err: GraphQLError) => err.message)
  }

  const executeQuery = async (query: TypedDocumentNode, variables = {}) => {
    const { data, error } = await nuxt.$urql.query(query, variables).toPromise()

    return error ? failure(parseErrors(error.graphQLErrors)) : success(data)
  }

  const executeMutation = async (mutation: TypedDocumentNode, variables = {}) => {
    const { data, error } = await nuxt.$urql.mutation(mutation, { input: variables }).toPromise()

    return error ? failure(parseErrors(error.graphQLErrors)) : success(data)
  }

  return { executeQuery, executeMutation }
}
